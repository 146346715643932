import React from 'react'
import { Helmet } from 'react-helmet'
import styles from './layout.module.css'

export default ({ children }) => (
  <div className={styles.layout}>
    <Helmet
    >
      <html lang="en" />
      <title>Liliana Folta | Visual Artist</title>
      <meta name="description" content="Portfolio website of visual artist Liliana Folta" />
      <meta name="keywords" content="art, paintings, ceramics, sculptures, installations" />
    </Helmet>
    { children }
  </div>
)

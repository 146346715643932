import React from 'react'
import { Link } from 'gatsby'
import styles from './header.module.css'

export default () => (
  <header className={styles.header}>
    <div className={styles.tagline}>Visual Artist</div>
    <Link to="/" className={styles.titleLink}>
      <h1 className={styles.title}>Liliana Folta</h1>
    </Link>
  </header>
)

import React from 'react'
import { Link } from 'gatsby'
import styles from './nav.module.css'

export default () => (
  <nav className={styles.navContainer}>
    <ul className={styles.nav}>
      <li><Link to="/bio/">Bio</Link></li>
      <li><Link to="/statement/">Statement</Link></li>
      <li><Link to="/studio/">Studio</Link></li>
      <li><Link to="/albums/">Works</Link></li>
      <li>
        <a href="https://www.instagram.com/liliana.foltaart/" rel="noopener noreferrer" target="_blank">
          Instagram
        </a>
      </li>
      <li><Link to="/news/">News</Link></li>
      <li><Link to="/cv/">CV</Link></li>
      <li><Link to="/contact/">Contact</Link></li>
    </ul>
  </nav>
)
